.booking-details {
    margin-top: 20px;
    background: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
}

.movie-detail-wrapper {
    padding-left: 10px;
}

.booking-title-wrapper {
    display: flex;
}

.booking-details span {
    float: left;
    margin-right: 15px;
}

.booking-details p {
    font-size: 14px;
    font-style: italic;
    align-self: flex-end;
    margin: 0 0 10px 10px;
}

.row {
    margin: 0px !important;    
}

.content-right {
    display: flex;
    align-items: center;
}

.sfGrandTotal h5 {
    margin: 0;
    font-size: 18px;
    float: left;
    margin-right: 5px;
}

.sfGrandTotal {
    padding: 0px 20px 0px;
    text-align: center;
    margin: auto 0;
}