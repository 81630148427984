.filter {
    margin-top: 20px;;
    padding-bottom:20px;
}

.ticketlist-wrapper ul {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.ticketlist-wrapper li {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    cursor: pointer;
    text-transform: capitalize;
}

.pagination li:first-child  {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.ticketlist-wrapper li.disabled {
    cursor: not-allowed;
    color: #575454;
}

.ticketlist-wrapper li.active {
    cursor: initial;
    color: #575454;
}

.ticketlist-wrapper li a:focus {
    outline: none;
}