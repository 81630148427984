

.ta-seatPlanGrid {
  background: #f8f9fa;
  overflow: auto;
  padding: 20px;
  margin-top: 20px;
}

table {
    max-width: 100%;
    background-color: transparent;
    width: 100%;
    margin-bottom: 20px;
}

.ta-seatPlanGrid tr td {
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  border-bottom: none;
  white-space: nowrap;
}

.ta-seatPlanGrid tr td span {
    cursor: pointer;
}

.cineSeats {
  background-repeat: no-repeat;
  display: inline-block;
  background-color: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.taAvailable {
    background: #ccc;
    color: #333;
    font-size: 10px;
}

.taSelectedSeat {
    background: #5eb000;
    color: #f2f2f2;
    font-size: 10px;
}

.taBookedSeat {
    background: #a3850a;
    color: #f2f2f2;
    font-size: 10px;
}

.taSoldSeat {
    background: #ab0000;
    color: #f2f2f2;
    font-size: 10px;
}

.taBlockedSeat {
    background: #111111;
    color: #f2f2f2;
    font-size: 10px;
}

.sfScreenLocation {
    background:  #ececec;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    letter-spacing: 15px;
    color: #000;
    text-align: center;
    margin: auto;
    margin-top: 15px;
    border-radius: 0px 0px 40px 40px;
    font-weight: 500;
}
