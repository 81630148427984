.screen-data {
  margin: 30px 0;
}

.screen-data h2 {
  border-left: 3px solid;
  padding-left: 10px;
}
h2.inner-header {
  color: #878585;
}
