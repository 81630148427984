.view-show-time {
    margin-top: 20px;
}


.audi-time {
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: 115px auto;
  align-items: center;
  grid-column-gap: 0rem;
  grid-row-gap: 1rem;
}

.audi-info p {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  line-height: 1.4;
  margin: 0;
  background: #9b9b9b;
  padding: 16px;
  color: #fff;
  min-height: 115px;
  height: 115px;
  display: flex;
  align-items: center;
}

.audi-info p span {
    font-size: 14px;
    width: 100%;
    display: block;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.4px;
}

ul.show-time-info {
  display: flex;
  background: #221e1f0a;
  align-items: center;
  height: 115px;
  margin: 0;
  padding: 0pc 30px;
}

ul.show-time-info li .show-time {
    color: #ededed;
    border: 2px solid #ffffff;
    padding: 10px 15px;
    margin-right: 24px;
    font-weight: 600;
    border-radius: 35px;
}

button.active{
    background: #4caf50;
}

span.no-available {
    background: #9b9b9b;
    cursor: not-allowed;
}
