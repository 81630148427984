.movieItem {
    cursor: pointer;
}

.img-wrap img {
    height: 100%;
    max-width: 100%;
    width: auto;
    display: inline-block;
    -o-object-fit: cover;
    object-fit: cover;
}

.card-product-grid .img-wrap {
    border-radius: 0.2rem 0.2rem 0 0;
}

.card-product-grid .info-wrap {
    overflow: hidden;
    padding: 18px 20px;
}

.info-wrap .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.info-wrap .spn-duration{
    font-size: 14px;
    font-style: italic;
}
