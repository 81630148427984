.title-wrapper {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 10px;
}

.title-wrapper span.title {
    font-weight: 500;
    font-size: 20px;
    text-decoration: underline;

}

.content-empty {
    background: #f8f9fa;
    border-radius: 18px;
    height: 30vh;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.content-empty span {
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
    color: #418294;
}

.movie-wrapper {
    gap:10px;
    margin-top: 20px !important;
}