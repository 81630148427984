.purchase-container{
    padding: 30px;
    border: 1px solid #eceeec;
    border-radius: 15px;
    max-width: 250px;
    align-items: center;
    text-align: center;
    margin: 20px 10px;
}

.btnPurchase {
    width: 150px;
    padding: 15px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
}