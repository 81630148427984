.ticket-row-content {
    display: flex;
    width: 100%;
    padding: 0;
    line-height: 0.7em;
}

.ticket-row-content .key {
    min-width: 80px;
    margin-right: 10px;
}

.ticket-row-content .value {
    font-weight: 500;

}