.movieItem {
    cursor: pointer;
}

.img-wrap img {
    height: 100%;
    max-width: 100%;
    width: auto;
    display: inline-block;
    -o-object-fit: cover;
    object-fit: cover;
}

.card-product-list .img-wrap {
    border-radius: 0.2rem 0.2rem 0 0;
    height: 280px;
    margin: auto;
}

.card-product-grid .info-wrap {
    overflow: hidden;
    padding: 18px 20px;
}

.info-wrap .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.info-wrap .spn-duration{
    font-size: 14px;
    font-style: italic;
}

[class*='card-product'] .badge {
    position: absolute;
    width: 45px;
    height: 45px;
    background: #e11e26;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    line-height: 3.2;
    font-weight: bold;
    right: 10px;
    top: 10px;
}

.badge-danger {
    color: #fff;
    background-color: #fa3434;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.37rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}



.movies-info {
    padding: 20px;
    box-sizing: content-box;
    overflow: hidden;
}

.movies-info h4 {
    color: #e11e26;
    font-size: 15px;
    position: relative;
    padding-left: 55px;
}

.movies-info h4:before {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    background: #e11e26;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.movies-info p {
    color: #221e1f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
}

ul:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
}

ul.movie-cast li {
    color: #221e1f;
    margin-bottom: 5px;
}

ul:not(.browser-default)>li {
    list-style-type: none;
}

ul.movie-cast li span {
    color: #e11f27;
    font-size: 16px;
    font-weight: 600;
}
